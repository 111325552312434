import React from 'react'
import $ from 'jquery'

import Element from './elements/Element'

class ExportPreview extends React.Component {
  constructor(props) {
    super(props)

    this.wbt = this.props.wbt
  }

  componentDidMount() {
    var document = this.props.document
    const script = document.createElement("script");
    script.id = 'jquery-js';
    script.src = "/dist/wbt/scripts/jquery.min.js?v=2.2";
    script.async = false;

    document.body.appendChild(script);

    var document = this.props.document
    const scriptt = document.createElement("script");
    scriptt.id = 'jquery-ui-js';
    scriptt.src = "/dist/wbt/scripts/jquery-ui.min.js?v=2.2";
    scriptt.async = false;

    document.body.appendChild(scriptt);

    var document = this.props.document
    const script1 = document.createElement("script");
    script1.id = 'daggable-js';
    script1.src = "/dist/wbt/scripts/draggable.bundle.legacy.min.js?v=2.2";
    script1.async = false;

    document.body.appendChild(script1);

    var document = this.props.document
    const script11 = document.createElement("script");
    script11.id = 'slick-js';
    script11.src = "/dist/wbt/scripts/slick.min.js?v=2.2";
    script11.async = false;

    document.body.appendChild(script11);

    const script2 = document.createElement("script");
    script2.id = 'main-js';
    script2.src = "/dist/wbt/scripts/main.min.js?v=2.2";
    script2.async = false;

    document.body.appendChild(script2);
  }

  modules() {
    var modules = this.wbt.modules.map((module) => {
      var elements = module.elements.map((element) => {
        return(
          <Element
            key={element.id}
            {...element}
          />
        )
      })
      var introslider = false
      if(elements.length === 1 && module.elements[0].type === 'introslider' && module.settings.headline == '') {
        introslider = true
      }
      return(
        <div key={module.id} className={'module' + (introslider ? ' full-width completed' : '')}>
          <div className="module-container">
            {
              module.settings.headline_show && module.settings.headline !== '' ?
                <h1>{module.settings.headline}</h1>
              :
                ''
            }
            <div className="elements">{elements}</div>
            {
              !introslider ?
                <button className="button next" data-completed-label="Fertig">Weiter</button>
              :
                ''
            }
          </div>
        </div>
      )
    })
    return modules
  }

  render() {
    var modules = this.modules();
    return(
      <div id="export-preview">
        <div id="wbt" className="wbt export-preview">
          <div className="wbt-container">
            <div className="modules">
              {modules}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ExportPreview
